const links = [
  { name: "Home", src: "/" },
  { name: "About", src: "/about" },
  { name: "Translation", src: "/translation" },
  // { name: "Contact", src: "/contact" },
  { name: "GitHub", src: "https://github.com/cpg55850" },
  { name: "LinkedIn", src: "https://www.linkedin.com/in/charlespgraham/" },
  {
    name: "Resumé",
    src:
      "https://drive.google.com/file/d/1xpzCH3WSE5I6GxAd5kwwSW2d3ACsS2Sm/view?usp=sharing",
  },
]

export default links
