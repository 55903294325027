import React from "react"
import GlobalStyles from "../styles/GlobalStyles"
import PropTypes from "prop-types"
import Sidebar from "./sidebar"
import { breakpoint } from "../styles"
import Container from "./container"

import styled from "styled-components"

const Layout = ({ children, noContainer }) => {
  return noContainer ? (
    <Wrapper>
      <GlobalStyles />
      <Sidebar />
      <main className="mainContent">{children}</main>
    </Wrapper>
  ) : (
    <Wrapper>
      <GlobalStyles />
      <Sidebar />
      <main className="mainContent">
        <Container>{children}</Container>
      </main>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* background-color: tan; */

  @media ${breakpoint.sm} {
    display: flex;
    /* margin-left: var(--navbar-width); */
    .mainContent {
      background-color: #20252d;
      padding-top: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      width: 100%;
    }
  }
  font-size: 16px;
  line-height: 1.5rem;
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
