export const breakpoint = {
  xs: "(min-width: 0)",
  sm: "(min-width: 600px)",
  md: "(min-width: 960px)",
  lg: "(min-width: 1280px)",
  xl: "(min-width: 1921px)",
}

export const size = {
  xs: "0px",
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1920px",
}

export const responsiveGridSizes = `
    grid-template-columns: 1fr;
    @media ${size.md} {
    grid-template-columns: repeat(2, 1fr);
    }

    @media ${size.lg} {
    grid-template-columns: repeat(4, 1fr);
    }
`
