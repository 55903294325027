import styled from "styled-components"

const Container = styled.div`
  max-width: 680px;
  margin: auto;
  padding: 0;

  h2,
  p {
    padding: 0 1rem;
  }
`

export default Container
