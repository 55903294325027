import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
  :root {
    --navbar-width: 280px;
  }

  * {
    box-sizing: border-box;
    
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: 12px;
    line-height: 1.5rem;
    background-color: #1a1b23;
    color: white;
  }

  input,
  textarea {
    font-family: 'Raleway', 'Segoe UI', Tahoma, Verdana, sans-serif;
  }

  h1, h2 {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  p {
    padding: 0.2rem 0;
    margin: 0;
  }
`
export default GlobalStyles
