import React from "react"
import styled from "styled-components"
import { breakpoint } from "../styles"
import { Link } from "gatsby"
import links from "../constants/links"
import { useLocation } from "@reach/router"

const Sidebar = () => {
  const { pathname } = useLocation()

  return (
    <Wrapper>
      <div className="contentContainer">
        <div className="topContent">
          <h2 className="name">Charles Graham</h2>
          <h4 className="title">
            {"Web Development / Translation ".toUpperCase()}
          </h4>
          {/* <h4 className="title">{"Translation".toUpperCase()}</h4> */}
          {/* <p>
            Hi, my name is Charles. I'm a software development student focusing
            on front-end and full stack web techonologies, like React, NextJS
            and Gatsby.
            <br />
            <br />I currently attend the University of Central Missouri as a
            senior student, and plan to graduate with a Bachelor's degree in
            Computer Science. I have worked on many projects at UCM, with other
            students and by myself. Here you will find several of the projects
            that I have worked on.
          </p> */}
          <br />
          <p></p>
        </div>

        <ul className="navList">
          <br />
          {links.map(link => (
            <li key={link.name}>
              <Link
                to={link.src}
                className={link.src === pathname ? "bold" : ""}
              >
                {link.name.toUpperCase()}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #131419;

  .bold {
    font-weight: 600;
  }

  .contentContainer {
    position: sticky;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    font-size: 16px;

    @media ${breakpoint.sm} {
      top: 0;
      left: 0;
      width: var(--navbar-width);
      overflow: auto;
      height: 100vh;
      padding: 2rem;
      font-size: 15px;
    }

    .name {
      padding: 0;
      margin: 0;
    }

    .title {
      font-weight: 400;
      font-size: 15px;
    }

    .topContent {
      flex-grow: 1;
      h2 {
        margin-bottom: 1rem;
      }
    }

    .navList {
      /* display: none; */
      list-style-type: none;
      margin: 0;
      padding: 0;

      @media ${breakpoint.sm} {
        display: block;
      }

      li {
        margin-bottom: 8px;
        a {
          font-size: 16px;
          text-decoration: none;
          color: inherit;

          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }
`

export default Sidebar
